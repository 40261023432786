<!--
 * @Author: 刘格优
 * @Date: 2020-06-16 17:34:45
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-06-30 10:15:54
--> 
<template>
  <div class="allbox">
    <div class="ztop">
      <div id="myChart"
           :style="{width: '230px', height: '230px',margin:'0 auto',paddingTop: '2vh'}"></div>
      <div class="ksfs">
        <div>
          <p class="one">{{ksdetail.testtime}}</p>
          <p class="two">考试日期</p>
        </div>
        <div>
          <p class="one">{{ksdetail.score}}</p>
          <p class="two">本次得分</p>
        </div>
        <div>
          <p class="one">{{ksdata.totalpoints}}</p>
          <p class="two">总分</p>
        </div>
      </div>
    </div>
    <div class="cxdt"
         @click="todt">
      <img src="@/assets/xxb/reback.png"
           alt="">
      <span>重新答题</span>
    </div>
    <div>
      <p class="title">{{title}}</p>
      <div class="bottombox">
        <ul v-if="one.length > 0"
            class="contentbox">
          <div class="typebg">
            <span>单选题</span>
            <span class="two">专业的考试助你提升开单技巧</span>
          </div>
          <li v-for="(item,index) in one"
              :key="index"
              class="ali">
            <div class="toptitle">
              <span>{{index+1}}、</span>
              <span>{{item.question}}</span>
              <span class="score">{{`（${item.score}分）`}}</span>
            </div>
            <div class="question">
              <ul>
                <li v-for="(citem,cindex) in item.questiondata"
                    :key="cindex"
                    :class="citem.showbg ? (!item.flag) ? 'red':'blue':'grey'">
                  <div class="custom-brief">{{citem.xuhao}}、{{ citem.content }}</div>
                </li>
              </ul>
            </div>
            <div class="parsing"
                 v-if="!item.flag">
              <div class="top">
                <img src="@/assets/xxb/wrong.png"
                     alt="">
                <span>答错了没关系~看看题目解析吧！</span>
              </div>
              <div class="middle">
                <span>正确答案：</span>
                <span class="answer">{{item.showanswer}}</span>
              </div>
              <div class="bottom">
                <!-- 慢慢坚持下来，我现在债务基本还清!每个月还有闲钱给爸妈，有前。 -->
                {{item.remark}}
              </div>
            </div>

          </li>
        </ul>
        <ul v-if="many.length > 0"
            class="contentbox">
          <div class="typebg">
            <span>多选题</span>
            <span class="two">专业的考试助你提升开单技巧</span>
          </div>
          <li v-for="(item,index) in many"
              :key="index"
              class="ali">
            <div class="toptitle">
              <span>{{index+1}}、</span>
              <span>{{item.question}}</span>
              <span class="score">{{`（${item.score}分）`}}</span>
            </div>
            <div class="question">
              <ul>
                <li v-for="(citem,cindex) in item.questiondata"
                    :key="cindex"
                    :class="citem.showbg ? (!item.flag) ? 'red':'blue':'grey'">
                  <div class="custom-brief">{{citem.xuhao}}、{{ citem.content }}</div>
                </li>
              </ul>
            </div>
            <div class="parsing"
                 v-if="!item.flag">
              <div class="top">
                <img src="@/assets/xxb/wrong.png"
                     alt="">
                <span>答错了没关系~看看题目解析吧！</span>
              </div>
              <div class="middle">
                <span>正确答案：</span>
                <span class="answer">{{item.showanswer}}</span>
              </div>
              <div class="bottom">
                <!-- 慢慢坚持下来，我现在债务基本还清!每个月还有闲钱给爸妈，有前。 -->
                {{item.remark}}
              </div>
            </div>
          </li>

        </ul>
        <ul v-if="judge.length > 0"
            class="contentbox">
          <div class="typebg">
            <span>判断题</span>
            <span class="two">专业的考试助你提升开单技巧</span>
          </div>
          <li v-for="(item,index) in judge"
              :key="index"
              class="ali">
            <div class="toptitle">
              <span>{{index+1}}、</span>
              <span>{{item.question}}</span>
              <span class="score">{{`（${item.score}分）`}}</span>
            </div>
            <div class="question">
              <ul>
                <li v-for="(citem,cindex) in item.reasons"
                    :key="cindex"
                    :class="citem.showbg ? (!item.flag) ? 'red':'blue':'grey'">
                  <div class="custom-brief">{{citem.xuhao}}、{{ citem.text }}</div>
                </li>
              </ul>
            </div>
            <div class="parsing"
                 v-if="!item.flag">
              <div class="top">
                <img src="@/assets/xxb/wrong.png"
                     alt="">
                <span>答错了没关系~看看题目解析吧！</span>
              </div>
              <div class="middle">
                <span>正确答案：</span>
                <span class="answer">{{item.showanswer}}</span>
              </div>
              <div class="bottom">
                <!-- 慢慢坚持下来，我现在债务基本还清!每个月还有闲钱给爸妈，有前。 -->
                {{item.remark}}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getresult } from '@/api/xxb/index'
let echarts = require('echarts/lib/echarts')
export default {
  data () {
    return {
      code: '',
      title: '',
      judge: [],
      many: [],
      one: [],
      reasons: [
        {
          value: 'Y',
          text: '对',
          xuhao: 'A',
          showbg: false
        },
        {
          value: 'N',
          text: '错',
          xuhao: 'B',
          showbg: false
        },
      ],
      ksdata: false,
      indexarr: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'
      ],
      ksdetail: {},
      dataArr: ''
    };
  },

  created () {
    this.code = this.$route.query.papercode
    this.getdetail()
  },
  mounted () {

  },

  methods: {
    myChart () {
      let columnar = this.$echarts.init(document.getElementById('myChart'));
      let data = this.dataArr;
      columnar.setOption({
        angleAxis: {
          show: false,
          max: 100 * 360 / 270, //-45度到225度，二者偏移值是270度除360度
          type: 'value',
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false
          }
        },
        barMaxWidth: 10, //圆环宽度
        radiusAxis: {
          show: false,
          type: 'category',
        },
        //圆环位置和大小
        polar: {
          center: ['50%', '50%'],
          radius: '180'
        },
        series: [{
          type: 'bar',
          data: [{ //上层圆环，显示数据
            value: this.dataArr,
            itemStyle: {
              color: { //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1, //从左到右 0-1
                y2: 0,
                colorStops: [{
                  offset: 0,
                  color: '#ffffff'
                }, {
                  offset: 1,
                  color: '#ffffff'
                }],
              },
            },
          }],
          barGap: '-100%', //柱间距离,上下两层圆环重合
          coordinateSystem: 'polar',
          roundCap: true, //顶端圆角
          z: 2 //圆环层级，同zindex
        },
        { //下层圆环，显示最大值
          type: 'bar',
          data: [{
            value: 100,
            itemStyle: {
              color: '#F06322'
            }
          }],
          barGap: '-100%',
          coordinateSystem: 'polar',
          roundCap: true,
          z: 1
        },
        //仪表盘
        {
          name: 'AQI',
          type: 'gauge',
          startAngle: 225, //起始角度，同极坐标
          endAngle: -45, //终止角度，同极坐标
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLabel: {
            show: false
          },
          pointer: {
            show: false
          },
          title: {
            offsetCenter: [0, -5],
            color: '#FFFFFF',
            backgroundColor: '#FF8A63',
            borderRadius: 100,
            padding: [23, 18, 23, 18],
            fontSize: 40,
            fontWeight: 500,
            rich: {
              a: {
                borderColor: '#fff',
                borderWidth: 1,
                borderRadius: 20,
                fontWeight: 'normal',
                fontSize: 12,
                color: '#FFF',
                padding: [8, 10, 8, 10],
              },
              b: {
                fontSize: 16,
                color: '#FFF',
              }
            }
          },
          detail: {
            formatter: function (e) {
              return '';
            },
            // color: '#fff',
            // fontSize: 16,
            // offsetCenter: [40, -15],
            // backgroundColor: '#FCC841',
            // borderRadius: 8,
            // padding: [4, 12],
          },
          data: [{
            // value: '',
            name: this.dataArr + '{b|' + '%' + '}' + '\n' + '{a|' + '本次正确率' + '}'
          }]
        },
        {
          type: "gauge",
          radius: "70",
          startAngle: 220,
          endAngle: -40,
          z: 2,
          axisTick: {
            show: true,
            lineStyle: {
              color: '#FF926B',
              width: 1
            },
            length: -8
          }, //刻度样式
          splitLine: {
            show: true,
            lineStyle: {
              color: '#FF926B',
              width: 1
            },
            length: -8
          }, //分隔线样式
          axisLabel: {
            color: 'rgba(255,255,255,0)',
            fontSize: 12,
          }, //刻度节点文字颜色
          pointer: {
            show: false
          },
          axisLine: {
            show: false
          },
          label: {
            show: false
          },
          //仪表盘详情，用于显示数据。
          detail: {
            show: true,
            offsetCenter: ['0%', '50%'],
            color: '#383838',
            formatter: function (params) {
              return ''
            },
            textStyle: {
              fontSize: 13
            }
          }
        },
        ]
      })
    },
    getdetail () {
      getresult({ papercode: this.code }).then((res) => {
        this.ksdetail = res.data.data
        this.ksdetail.testtime = this.ksdetail.testtime.substr(5, 5)
        this.ksdata = JSON.parse(res.data.data.content)
        this.dataArr = this.ksdetail.accuracy.substring(0, this.ksdetail.accuracy.length - 1)
        console.log(this.ksdata)
        this.title = this.ksdata.title
        document.title = this.ksdata.title
        this.judge = this.ksdata.judge
        this.judge.forEach((item, index) => {
          let data = [
            {
              value: 'Y',
              text: '对',
              xuhao: 'A',
              showbg: false
            },
            {
              value: 'N',
              text: '错',
              xuhao: 'B',
              showbg: false
            },
          ]
          item['reasons'] = data
        })
        this.many = this.ksdata.many
        this.one = this.ksdata.one
        this.one.forEach((item, index) => {
          item.questiondata.forEach((citem, cindex) => {
            this.$set(citem, 'xuhao', this.indexarr[cindex])
            if (item.myanswer == citem.itemcode) {
              this.$set(citem, 'showbg', true)
            }
          })
        })
        this.many.forEach((item, index) => {
          item.myanswer = item.myanswer.split(',')
          item.questiondata.forEach((citem, cindex) => {
            this.$set(citem, 'xuhao', this.indexarr[cindex])
            item.myanswer.forEach((bitem, bindex) => {
              if (bitem == citem.itemcode) {
                this.$set(citem, 'showbg', true)
              }
            })
          })
        })
        this.judge.forEach((item, index) => {
          item.reasons.forEach((citem, cindex) => {
            if (item.myanswer == citem.value) {
              citem.showbg = true
            }
          })
        })
        this.myChart() //函数调用
      })
    },
    randomArr (arr, myanswer) {
      arr.forEach((item, index) => {
        this.$set(arr[index], 'xuhao', this.indexarr[index])
        if (myanswer == item.itemcode) {
          this.$set(item, 'showbg', true)
        }
      })
      return arr;
    },
    todt () {
      this.$router.push({
        path: `/testdetail?papercode=${this.code}`,
      })
    }
  }
}

</script>
<style scoped lang="stylus">
.allbox {
  background-color: #ffffff;
}

.ksfs {
  display: flex;
  justify-content: space-around;

  div {
    text-align: center;

    .one {
      color: #FFFFFF;
      font-size: 0.5rem;
      font-weight: bold;
    }

    .two {
      font-size: 0.36rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      opacity: 0.6;
      padding-top: 0.2rem;
    }
  }
}

.title {
  font-size: 0.45rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(56, 56, 56, 1);
  padding: 0.67rem 0 0 0.85rem;
}

.bottombox {
  padding: 0.4rem 0.4rem;
}

.contentbox {
  box-shadow: 0rem 0.1rem 0.1rem #e8e8e8;
  border: 0.01rem solid #e8e8e8;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
}

.ztop {
  width: 100%;
  height: 50vh;
  background-image: url('~@/assets/xxb/ksbg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.cxdt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.4rem;
  height: 1.3rem;
  border-radius: 0.3rem;
  // box-shadow: 0rem 0.1rem 0.1rem #EDEDED;
  border: 0.01rem solid #ededed;
  box-shadow: 0.01rem 0.1rem 0.1rem #ededed;

  img {
    width: 0.54rem;
    height: auto;
  }

  span {
    color: #333333;
    font-size: 0.39rem;
    padding-left: 0.3rem;
  }
}

.typebg {
  background-image: url('~@/assets/xxb/typebg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 0 0.5rem;
  height: 1rem;
  line-height: 1rem;

  span {
    color: #7F5140;
    font-size: 0.43rem;
    font-weight: 400;
  }

  .two {
    font-size: 0.3rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7F5140;
    padding-left: 0.3rem;
  }
}

.ali {
  padding: 0.4rem 0.5rem 0 0.4rem;
  border-bottom: 1px solid #F5F5F5;

  .toptitle {
    color: #323232;
    font-size: 0.43rem;
    font-weight: bold;
    line-height: 0.68rem;

    .score {
      color: #00a0f3;
      font-weight: 100;
      font-size: 0.36rem;
    }
  }
}

.question {
  display: flex;

  ul {
    width: 100%;
    padding: 0.2rem 0.2rem;

    li {
      width: 100%;
      padding: 0.32rem 0.3rem;
      font-size: 0.36rem;
      color: #373D48;
      margin-bottom: 0.25rem;
    }
  }

  .leftbox {
    .left {
      height: 1rem;
      line-height: 1rem;
      margin: 0.12rem 0 0.12rem 0.4rem;
    }
  }

  .rightbox {
    .right {
      height: 1rem;
      line-height: 1rem;
    }
  }

  .blue {
    background-color: #F0FBFF;
    color: #00A0F3;
  }

  .red {
    background-color: #FFF1F0;
    color: #FF756B;
  }

  .grey {
    background-color: #f7f7f7;
  }
}

.parsing {
  .top {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;

    img {
      width: 0.75rem;
      height: auto;
    }

    span {
      color: #323232;
      font-size: 0.36rem;
      font-family: PingFang SC;
      font-weight: 500;
      padding-left: 0.3rem;
    }
  }

  .middle {
    color: #676767;
    padding: 0.5rem 0.3rem 0.2rem;

    span {
      font-size: 0.36rem;
      font-family: PingFang SC;
      font-weight: 500;
    }

    .answer {
      color: #4DA6E6;
    }
  }

  .bottom {
    color: #666666;
    font-size: 0.39rem;
    line-height: 0.7rem;
    padding: 0 0.3rem 0.5rem 0.3rem;
  }
}

.custom-brief {
  font-size: 0.36rem;
}
</style>