import axios from "@/lib/api.request";

/* 
  @module: 公共
*/
export const readWz = data => axios.post("/abt/abtComXbWz/readWz", data);
//搜索
export const selectXbWz = data =>
  axios.post("/abt/abtComXbWz/selectXbWz", data);
/* 
   @module : 推荐
   @author : 贾峰
*/
//推荐列表
export const getwzbycode = data =>
  axios.post("/abt/abtComXbWz/getwzbycode", data);
//个人中心
export const wzhistory = data => axios.post("/abt/abtComXbWz/wzhistory", data);
// 考试中心
export const paperlist = data =>
  axios.post("/abt/abtComXbTestpaper/list", data);
// 考试详情
export const getresult = data =>
  axios.post("/abt/abtComXbTestresult/getresult", data);
// 考试提交
export const testres = data =>
  axios.post("/abt/abtComXbTestresult/testres", data);
/*
  @module:问答
  @author:格优
  */
// 问答列表
export const wdlist = data => axios.post("/abt/abtComQaQuestion/list", data);
// 问答添加
export const addQuestion = data =>
  axios.post("/abt/abtComQaQuestion/addQuestion", data);
// 置顶
export const settop = data => axios.post("/abt/abtComQaQuestion/settop", data);
// 删除
export const delQuestion = params =>
  axios.delete("/abt/abtComQaQuestion/delQuestion", {
    params
  });
// 评论问题
export const addAnswer = data =>
  axios.post("/abt/abtComQaAnswer/addAnswer", data);
// 删除评论
export const delAnswer = params =>
  axios.delete("/abt/abtComQaAnswer/delAnswer", {
    params
  });
// 问题评论的回复
export const addReply = data => axios.post("/abt/abtComQaReply/addReply", data);
// 问题评论的回复删除
export const delReply = params =>
  axios.delete("/abt/abtComQaReply/delReply", {
    params
  });
// 问题详情
export const getQuestionmsg = params =>
  axios.get("/abt/abtComQaQuestion/getQuestionmsg", {
    params
  });
// 我的消息
export const myMsg = data => axios.post("/abt/abtComQaQuestion/myMsg", data);
// 我的收藏列表
export const getwzcollect = data =>
  axios.post("/abt/abtComXbWzcollect/getwzcollect", data);
// 添加收藏
export const addwzcollect = data =>
  axios.post("/abt/abtComXbWzcollect/addwzcollect", data);
// 取消收藏
export const delwzcollect = params =>
  axios.delete("/abt/abtComXbWzcollect/delwzcollect", {
    params
  });
// 个人中心数量查询
export const myPage = data => axios.post("/abt/abtComQaQuestion/myPage", data);
// 我的提问
export const myQuestion = data =>
  axios.post("/abt/abtComQaQuestion/myQuestion", data);
